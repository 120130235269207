import { NewerRewardModal } from '@/components/modal';
import { Desktop, DesktopOrTablet } from '@/components/responsive';
import { useGoogleLogin } from '@/core/hooks';
import { GoogleLogin } from '@/core/shared';
import { localStorageApi, LOCAL_KEY, useAppContext } from '@/core/store';
import { dispatchGeolocation } from '@/core/utils';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import css from 'styled-jsx/css';
import Announcement from './components/announcement';
import HeaderBanner from './components/banner';
import SidebarBonus from './components/bonus';
import Download from './components/download';
import Excellence from './components/excellence';
import LazyMarkets from './components/markets';
import Media from './components/media';
import Partners from './components/partners';
import Quotes from './components/quotes';
import Slide from './components/slide';
import Steps from './components/steps';

export default function Main() {
  const { ref: partnersRef, inView: partnersInView } = useInView();
  const [visible, setVisible] = useState(false);
  const { GoogleLoginCallback } = useGoogleLogin();
  const { isLogin } = useAppContext();
  const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_ONETAP_CLIENT_ID;

  useEffect(() => {
    dispatchGeolocation();
    GoogleLogin(GoogleLoginCallback);
    const func = () => {
      setVisible(localStorageApi.getItem(LOCAL_KEY.FIRST_REGISTER_MODAL_VISIBLE) || false);
    };
    func();
    localStorageApi.setItem(LOCAL_KEY.FIRST_REGISTER_MODAL_VISIBLE, false);
  }, []);
  return (
    <div className='container'>
      <Desktop>
        <div
          id='g_id_onload'
          data-client_id={GOOGLE_CLIENT_ID}
          data-auto_prompt={!isLogin}
          data-context='signin'
          data-login_uri='/'
          data-callback='google_login_callback'
        ></div>
      </Desktop>
      <HeaderBanner />
      <DesktopOrTablet>
        <Announcement />
        <Slide />
      </DesktopOrTablet>
      <Quotes />
      <LazyMarkets />
      <Steps />
      <Excellence />
      <Download />
      <Media />
      <DesktopOrTablet>
        <SidebarBonus />
      </DesktopOrTablet>
      {visible && <NewerRewardModal visible={visible} onCloseNewerRewardModal={() => setVisible(false)} />}
      <div ref={partnersRef}>{partnersInView && <Partners />}</div>
      <style jsx>{styles}</style>
    </div>
  );
}
const styles = css`
  .container {
    background: var(--spec-background-color-2);
    overflow: hidden;
  }
`;
