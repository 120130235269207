export const options = [
  [
    '/static/images/home/media/bitcoin.svg',
    `BYDFi Copy Trading system doesn't only benefit copiers lfo listed trader the copy trading system also brings them extra incomes...`,
    'Bitcoin.com',
  ],
  [
    '/static/images/home/media/forbes_1.svg',
    `BYDFi is a good option for investors who are interested in frequently trading cryptocurrencies, especially through more advanced trades...`,
    'Forbes',
  ],
  [
    '/static/images/home/media/Cybernews_1.svg',
    `BYDFi is an all-around crypto trading platform that offers a secure and feature-rich trading experience for both beginners and experienced traders...`,
    'Cybernews',
  ],
  [
    '/static/images/home/media/Crypto.news.svg',
    `BYDFi offers a user-friendly navigation and trading experience, suitable for traders of all skill levels. With its coin management protocols...`,
    'Crypto.news',
  ],
  [
    '/static/images/home/media/AMBCrypto.svg',
    `BYDFi also has developed a professional copy trading system that will greatly benefit both copiers and signal...`,
    'AMBCrypto',
  ],
  [
    '/static/images/home/media/BitDegree.svg',
    `BYDFi is a company focused on providing essential tools and utilities to investors, both individuals and...`,
    'BitDegree',
  ],
  [
    '/static/images/home/media/Nasdaq.svg',
    `BYDFi is creating a precedent in the industry with complex contracts and simple transactions...`,
    'Nasdaq',
  ],
  [
    '/static/images/home/media/Bitcoinist.com.svg',
    `BYDFi is a global cryptocurrency trading platform that offers an advanced range of crypto products and services...`,
    'Bitcoinist.com',
  ],
];
