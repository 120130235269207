import Image from '@/components/image';
import { NoSSR } from '@/components/no-ssr';
import { Desktop } from '@/components/responsive';
import TypeWriter from '@/components/type-writer';
import { getCommonUePublicitiesApi } from '@/core/api';
import { useRequestData, useTheme } from '@/core/hooks';
import { useRouter } from '@/core/hooks/src/use-router';
import { LANG } from '@/core/i18n';
import { TrLink } from '@/core/i18n/src/components/tr-link';
import { useAppContext } from '@/core/store';
import { clsx } from '@/core/utils/src/clsx';
import { MediaInfo } from '@/core/utils/src/media-info';
import { message } from '@/core/utils/src/message';
import dayjs from 'dayjs';
import { memo, useState } from 'react';
import css from 'styled-jsx/css';

function HeaderBanner() {
  const [email, setEmail] = useState('');
  const { isLogin } = useAppContext();
  const { isDark } = useTheme();
  const router = useRouter();
  const [carouselData = [], , , isLoading] = useRequestData(getCommonUePublicitiesApi, {
    params: {
      platform: 'web',
      type: '7',
    },
    enableCache: false,
    enableIsLoading: true,
  });
  const register = () => {
    const url = `/register?email=${email}`;
    if (
      /^[a-z0-9A-Z]+([._\\-]*[a-z0-9A-Z_])*@([a-z0-9A-Z]+[-a-z0-9A-Z]*[a-z0-9A-Z]+.){1,63}[a-z0-9A-Z]+$/.test(email)
    ) {
      router.push(url);
    } else {
      message.error(LANG('邮箱格式错误'));
    }
  };
  const renderBannerButton = () => {
    return isLogin ? (
      <TrLink href={`/swap/btc-usdt`} className='trade_link'>
        {LANG('Start Trading Now')}
      </TrLink>
    ) : (
      <div className='email_input'>
        <input
          type='text'
          placeholder={LANG('请输入邮箱')}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
        />
        <button onClick={register}>{LANG('注册')}</button>
      </div>
    );
  };
  const renderFooterBanner = () => {
    if (!carouselData) return null;
    const carouselItem = carouselData[0];
    const carouselActiveTime = carouselItem?.activeTime;
    const carouselExpireTime = carouselItem?.expireTime;
    const contentParts = carouselItem?.content.split(';');
    const titleParts = carouselItem?.title.split(';');
    const carouselContent: { title: string; content: string }[] = [];

    for (let i = 0; i < contentParts?.length; i++) {
      const contentItem = contentParts[i];
      const titleItem = titleParts[i];
      carouselContent.push({ title: titleItem, content: contentItem });
    }
    const now = dayjs().unix() * 1000; // 将秒转换为毫秒
    // 判断逻辑
    const isActive = now >= carouselActiveTime;
    const isExpired = now > carouselExpireTime;
    if (isLoading) return null;
    if (isExpired) return null;
    if (isActive) {
      return carouselContent?.map((item: { title: string; content: string }) => {
        return (
          <div className='card' key={item.title}>
            <p className='title'>{item.title}</p>
            <p className='content'>{item.content}</p>
          </div>
        );
      });
    }
    return null;
  };
  return (
    <div className='banner-container'>
      <div className={clsx('top')}>
        <i className={clsx('l-border')} />
        <i className={clsx('c-border')} />
        <i className={clsx('r-border')} />
        <div className='top-left'>
          <div className='title'>
            <div style={{ display: 'flex', alignItems: 'center', direction: 'ltr' }}>
              <TypeWriter text='BUIDL' speed={300} className='buidl-text' />
              &nbsp;Your
            </div>
            <p>Dream Finance</p>
          </div>
          <h3 className='text'>Buy and sell BTC, ETH, LTC, DOGE, and other altcoins</h3>
          <NoSSR>{renderBannerButton()}</NoSSR>
        </div>
        <div className='top_right'>
          <Image src={'/static/images/home/banner-bg.png'} width={454} height={372} />
        </div>
      </div>
      <Desktop>
        <div className='footer-banner'>{renderFooterBanner()}</div>
      </Desktop>
      <style jsx>{styles}</style>
    </div>
  );
}
export default memo(HeaderBanner);
const styles = css`
  .banner-container {
    width: 100%;
    @media ${MediaInfo.desktop} {
      height: 524px;
    }
    background: var(--spec-background-color-1);
    overflow: hidden;
    p {
      padding: 0;
      margin: 0;
    }
    .top {
      max-width: var(--const-max-page-width);
      margin: 0 auto;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      .l-border,
      .c-border,
      .r-border {
        position: absolute;
        width: 1px;
        height: 100%;
        display: none;
        background: var(--skin-border-color-1);
        top: 0;
      }
      .l-border {
        left: -100px;
      }
      .c-border {
        left: 630px;
      }
      .r-border {
        right: -100px;
      }
      .top-left {
        padding-top: 44px;
      }
      .top-right {
        margin-left: 148px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 72px;
        font-weight: 700;
        line-height: 1.2;
        white-space: nowrap;
        color: var(--theme-font-color-1);
        span {
          color: var(--skin-color-active);
        }
      }
      .text {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.5;
        padding: 24px 0 20px;
        color: var(--spec-font-color-2);
      }
      :global(.trade_link) {
        background: var(--skin-primary-color);
        font-weight: 400;
        min-width: 250px;
        border-radius: 6px;
        text-align: center;
        margin-right: 10px;
        font-size: 20px;
        font-weight: 500;
        color: var(--skin-font-color);
        padding: 12px 20px;
        display: inline-block;
        cursor: pointer;
        &:active {
          transform: translateY(1px);
        }
        &:disabled,
        &.disabled {
          opacity: 0.6;
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.4;
        }
      }
      :global(.email_input) {
        height: 60px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        @media ${MediaInfo.mobile} {
          margin-right: 0px;
        }
        background: var(--spec-background-color-2);
        position: relative;
        overflow: hidden;
        :global(input) {
          outline: none;
          border: none;
          background: var(--spec-background-color-2);
          &:focus {
            border: none;
          }
          flex: 1;
          height: 100%;
          padding: 0 8px 0 24px;
          font-size: 16px;
          font-weight: 500;
          color: var(--spec-font-color-2);
        }
        :global(button) {
          cursor: pointer;
          border: none;
          min-width: 120px;
          border-radius: 6px;
          background: var(--skin-primary-color);
          text-align: center;
          margin-right: 10px;
          font-size: 16px;
          font-weight: 500;
          color: var(--skin-font-color);
          display: inline-block;
          padding: 10px 24px;
        }
      }
      @media ${MediaInfo.tablet} {
        flex-direction: column;
        padding-bottom: 0;
        padding: 0 32px;
        .top_right {
          padding-top: 15px;
          width: 100%;
          :global(img) {
            width: 100%;
            height: auto;
          }
        }
      }
      @media ${MediaInfo.mobile} {
        padding: 0 16px 60px;
        .top_right {
          display: none;
        }
        .top_left {
          padding-top: 30px;
        }
        .title {
          font-size: 35px;
        }
        .text {
          font-size: 14px;
          font-weight: 500;
          width: 210px;
          padding: 15px 0;
        }
        :global(.trade_link) {
          font-size: 12px;
          font-weight: 500;
          min-width: 100px;
          padding: 6px 10px;
        }
        :global(.email_input) {
          height: 38px;
          padding: 0 4px 0 12px;
          :global(input) {
            padding-left: 0;
            font-size: 12px;
          }
          :global(button) {
            min-width: 80px;
            padding: 5px 12px;
            font-size: 12px;
          }
        }
      }
    }
    .footer-banner {
      margin: 0px auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 15px;
      max-width: var(--const-max-page-width);
      padding-bottom: 42px;
      :global(.card) {
        word-break: break-all;
        background-color: var(--theme-card-bg-color);
        border-radius: 20px;
        padding: 20px;
        height: 100px;
        border: 1px solid var(--theme-border-color-7);
        :global(.title) {
          font-size: 18px;
          font-weight: 600;
          color: var(--theme-font-color-1);
        }
        :global(.content) {
          font-size: 14px;
          color: var(--spec-font-color-2);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制为两行 */
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 4px;
          word-break: break-word;
        }
      }
    }
  }
`;
