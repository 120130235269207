import { Account } from '@/core/shared';

export const GoogleLogin = (callback: Function): Promise<string> => {
  return new Promise((resolve, reject) => {
    const googleScript = document.getElementById('google-signin');
    if (googleScript) {
      document.body.removeChild(googleScript);
    }

    // @ts-ignore
    window.google_login_callback = (data: any) => {
      const id_token = data.credential;
      resolve(id_token);
      callback(id_token);
    };
    const run = () => {
      // @ts-ignore
      if (!google || Account.isLogin) return;
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_ONETAP_CLIENT_ID, // '707899493208-k3eulscpuieo487p9jog5g2m0bqhb0id.apps.googleusercontent.com'
        // @ts-ignore
        callback: window.google_login_callback,
        use_fedcm_for_prompt: true,
      });
      // @ts-ignore
      google.accounts.id.prompt(); // 显示One Tap对话框
    };
    const googleAuthUrl = `https://accounts.google.com/gsi/client`;
    const script = document.createElement('script');
    script.src = googleAuthUrl;
    script.id = 'google-signin';
    document.body.appendChild(script);
    script.onload = run;
  });
};
