import React, { useContext } from 'react';

export type ThirdPartAuthContextValue = {
  onLoginSuccess: () => any;
};
export const ThirdPartAuthContext = React.createContext<ThirdPartAuthContextValue>({
  onLoginSuccess: () => {},
});
export const useThirdPartAuth = () => useContext(ThirdPartAuthContext);
export const ThirdPartAuthProvider = ({ children, value }: { children: any; value: ThirdPartAuthContextValue }) => {
  return <ThirdPartAuthContext.Provider value={value}>{children}</ThirdPartAuthContext.Provider>;
};
